@import '../../assets/styles/vars';

.rr-grid-image {
    width: 40px;
    height: 40px;
    clip-path: circle(20px at center);
}

.rr-grid-full-image {
    max-height: 200px;
    height: 200px; // TODO
}

.rr-grid-footer-right {
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: start;
    column-gap: 10px;
    row-gap: 20px;
    width: 100%;
}

.rr-grid-footer {
    padding-top: 30px;
    display: flex;
}

@media (min-width: 992px) {
    .rr-grid-footer-right {
        justify-content: end;
    }
}

@media (max-width: 991px) {
    .rr-grid-footer-right,
    .rr-grid-footer-left {
        width: 100%;
    }
    .rr-grid-footer {
        flex-wrap: wrap;
        row-gap: 16px;
    }
}

.rr-grid-header {
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-pagination-next {
        .anticon {
            font-size: 15px !important;
        }
    }
}

.rr-grid-header .anticon {
    font-size: 22px;
}

.rr-grid-header-left {
    display: flex;
    align-items: center;
}

.rr-grid-header-right {
    display: flex;
    align-items: center;
    justify-content: right;
}

.rr-grid-header-right > *:not(:last-child) {
    margin-right: 17px;
}

.rr-grid-header-right > *:last-child {
    margin-right: 3px;
}

/////// Блок с фильтрами

.ant-form-inline .rr-grid-filters .ant-form-item > .ant-form-item-control-wrapper,
.ant-form-inline .rr-grid-filters .ant-form-item > .ant-form-item-label {
    //display: inline-block;
    vertical-align: middle;
}

.ant-form-inline .rr-grid-filters .ant-form-item .ant-switch {
    margin: 0;
}

.rr-grid-filters {
    display: flex;
    align-items: normal;
    margin-bottom: 19px;

    .ant-form-item-control-wrapper {
        //max-height: 38px !important;
    }
}

.rr-grid-filters-right {
    float: right;
    margin-left: 8px;
    flex: 0 0 auto;
    height: 38px;
    display: inline-flex;
    align-items: center;
}

.rr-grid-filters-left {
    display: inline-block;
    flex: 1 1;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rr-grid-filters-left .ant-form-item {
    white-space: nowrap;

    //display: flex;
    //flex-wrap: nowrap;
}

.rr-grid-filters-left .ant-form-item-control-wrapper {
    flex: 1 1;
}

.rr-grid-filters-left .ant-form-item > .ant-form-item-label {
    width: auto;
    //flex: 0 0 auto;

    overflow: hidden;
    text-overflow: ellipsis;
}

.rr-grid-filters-left .ant-form-inline .ant-form-item {
    margin-right: 0px;
}

.rr-grid-filters-left .ant-row .ant-col,
.rr-grid-filters-left .ant-row-flex .ant-col {
    margin-bottom: 8px;
}

.rr-grid-filters-left .ant-row .ant-col .ant-row.ant-form-item .ant-col,
.rr-grid-filters-left .ant-row-flex .ant-col .ant-row.ant-form-item .ant-col {
    margin-bottom: 0px;
}

.rr-grid-filters-left .ant-form-inline .ant-form-item:not(:last-child) {
    padding-right: 30px;
}

.rr-grid-filters-right .ant-form-item:last-child {
    margin-right: 0px;
}

/////// Умные фильтры и кнопка открытия/закрытия

.rr-grid-smart_filters {
    background-color: #f4f5f8;
    //padding: 25px+4px 30px 25px-4px 30px;
    padding: 0px;
    padding-top: 30px;
    margin-bottom: 17px;
}

.rr-grid-smart_filters .ant-form-inline .ant-form-item {
    margin-bottom: 28px;

    //border: 1px solid #000 !important;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-right: 0;
    padding-left: 15px;
    padding-right: 15px;
}

@media (min-width: 1785px) {
    .rr-grid-smart_filters .ant-form-inline {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }

    .rr-grid-smart_filters .ant-form-inline .ant-form-item:nth-child(3n) {
        border: none !important;
    }

    .rr-grid-smart_filters .ant-form-inline .ant-form-item {
    }
}

@media (min-width: 1550px) and (max-width: 1785px) {
    .rr-grid-smart_filters .ant-form-inline {
        display: grid;

        grid-template-columns: repeat(2, 1fr);
    }

    .rr-grid-smart_filters .ant-form-inline .ant-form-item:nth-child(2n) {
        border: none !important;
    }
    //.rr-grid-smart_filters .ant-form-inline .ant-form-item:nth-child(2) {
    //    border: none !important;
    //}
    //.rr-grid-smart_filters .ant-form-inline .ant-form-item:nth-child(4) {
    //    border: none !important;
    //}

    .rr-grid-smart_filters .ant-form-inline .ant-form-item {
    }
}

@media (max-width: 1550px) {
    .rr-grid-smart_filters .ant-form-inline {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }

    .rr-grid-smart_filters .ant-form-inline .ant-form-item {
        border: none !important;
    }

    .rr-grid-smart_filters .ant-form-inline .ant-form-item {
    }
}

.rr-grid-smart_filters .ant-form-inline {
    padding: 0px;
    //display: flex;
    //justify-content: space-around;
    // flex-wrap:  wrap;
    //flex-direction: row;
}

@media (min-width: 1100px) {
    .rr-grid-smart_filters .ant-form-inline .ant-form-item:not(:last-child) {
        //padding-right: 30px;
        border-right: 2px solid #dbdde6;
    }
}

.rr-grid-smart_filters .ant-form-inline .ant-form-item {
    // margin-right: 27px;
}

@media (min-width: 800px) {
    .rr-grid-smart_filters .ant-form-item-control {
        white-space: nowrap;
    }
}

.rr-grid-smart_filters-toggle-btn {
    color: #575962;
    user-select: none;
    margin-left: 30px;
    cursor: pointer;
}

.rr-grid-smart_filters-toggle-btn .anticon.anticon-down svg {
    transition: transform 0.3s, -webkit-transform 0.3s;
}

.rr-grid-smart_filters-toggle-btn:hover {
    color: lighten(#575962, 10%);
}

.rr-grid-smart_filters-toggle-btn .anticon:not(.anticon-down) {
    font-size: 24px;
    margin-right: 6px;
    vertical-align: middle;
}

.rr-grid-smart_filters-toggle-btn .anticon.anticon-down {
    position: relative;
    margin-left: 8px;
    vertical-align: middle;
}

@media (max-width: 1199px) {
    .rr-grid-smart_filters-toggle-btn > span {
        display: none;
    }

    .rr-grid-smart_filters-toggle-btn > .anticon.anticon-down {
        margin-left: 0px;
    }
}

/////// Блок с действиями

.rr-grid-actions {
    margin-bottom: 26px - 12px;
}

/*.rr-grid-actions > div > * {
  margin-top: 2px;
  margin-bottom: 2px;
}*/

.rr-grid-actions > div > *:not(:last-child) {
    margin-right: 20px;
}

.rr-grid-actions > div > * {
    margin-bottom: 12px;
}

.rr-grid-actions .ant-btn-round.ant-btn-lg.rr-round-btn {
    transition: all 0s;
}

@media (max-width: 1199px) {
    .rr-grid-actions .ant-btn-round.ant-btn-lg.rr-round-btn {
        padding: 0px 10px;
        width: 40px;
    }

    .rr-grid-actions button.ant-btn-round span {
        display: none;
    }
}

.rr-grid-phone-cell {
    white-space: nowrap;
}

.rr-grid-phone-cell .anticon,
.rr-grid-phone-cell .anticon {
    opacity: 0;
    font-size: 18px;
    vertical-align: top;
}

.rr-grid-phone-cell > span {
    margin-right: 5px;
    padding-left: 23px;
}

.rr-grid-phone-cell:hover .anticon,
.rr-grid-phone-cell:hover .anticon {
    opacity: 1;
}

.rr-is-touch-device .rr-grid-phone-cell .anticon,
.rr-is-touch-device .rr-grid-phone-cell .anticon {
    opacity: 1;
}

.rr-grid-email-cell {
    text-align: left;
    font-weight: 600;
    color: @rr-link-default-color;
}

.rr-grid-email-cell .anticon {
    opacity: 0;
    font-size: 18px;
    vertical-align: top;
}

.rr-grid-email-cell:hover .anticon,
.rr-grid-email-cell:hover .anticon {
    opacity: 1;
}

.rr-is-touch-device .rr-grid-email-cell {
    opacity: 1;
}

.rr-grid-email-cell a {
    color: @rr-link-default-color;
    text-decoration: none;
}

.rr-grid-email-cell a:hover {
    color: @rr-link-hover-color;
    text-decoration: underline;
}

.rr-grid-text-cell {
    text-align: left;
}

.rr-grid-text-cell a,
.rr-grid-text-cell a:hover {
    color: unset;
}

.rr-grid-text-cell-center {
    text-align: center;
}

.rr-grid-text-cell-center a,
.rr-grid-text-cell-center a:hover {
    color: unset;
}

.rr-grid-shortName-cell {
    text-align: left;
    font-weight: 600;
    color: @rr-link-default-color;
}

.rr-grid-shortName-cell-gray {
    color: @rr-colors-grey-1!important;
}

.rr-grid-shortName-cell a {
    color: @rr-link-default-color;
    text-decoration: none;
}

.rr-grid-shortName-cell a:hover {
    color: @rr-link-hover-color;
    text-decoration: underline;
}

//
.rr-grid-shortName-cell-hover:hover {
    //color: @rr-link-hover-color;
    opacity: 0.8;
    text-decoration: underline;
    cursor: pointer;
}

.rr-grid-id {
    text-align: left;
    font-weight: 600;
    color: @rr-link-default-color;
}

.rr-grid-id a {
    color: @rr-link-default-color;
    text-decoration: none;
}

.rr-grid-id a:hover {
    color: @rr-link-hover-color;
    text-decoration: underline;
}

//
.rr-grid-instanceCount-cell {
    font-weight: 600;
    color: @rr-link-default-color;
}

.rr-grid-new-element-cell {
    color: #1cb3a6;
}

.rr-grid-problem-row,
.rr-grid-problem-row a,
.rr-grid-problem-row a:hover,
.rr-grid-problem-row .anticon,
.rr-grid-problem-row .rr-grid-instanceCount-cell,
.rr-grid-problem-row .rr-grid-shortName-cell,
.rr-grid-problem-row .rr-grid-id {
    color: #f4516c;
}

.rr-grid-problem-row a:hover .rr-delay-icon,
.rr-grid-problem-row a:hover .rr-shortage-icon {
    color: fade(#f4516c, 70%);
}

.rr-grid-archive-row,
.rr-grid-archive-row a,
.rr-grid-archive-row a:hover,
.rr-grid-archive-row .anticon,
.rr-grid-archive-row .rr-grid-shortName-cell,
.rr-grid-archive-row .rr-grid-id,
.rr-grid-archive-row .rr-grid-instanceCount-cell,
.ReactTable .rt-tbody .rr-grid-archive-row .rt-td .main-value {
    color: #abacb0 !important;
}

.rr-grid-problem-row.rr-grid-archive-row,
.rr-grid-problem-row.rr-grid-archive-row a,
.rr-grid-problem-row.rr-grid-archive-row a:hover,
.rr-grid-problem-row.rr-grid-archive-row .anticon,
.rr-grid-problem-row.rr-grid-archive-row .rr-grid-instanceCount-cell,
.rr-grid-problem-row.rr-grid-archive-row .rr-grid-shortName-cell,
.rr-grid-problem-row.rr-grid-archive-row .rr-grid-id {
    color: #f78999;
}

.rr-grid-disabled-row,
.rr-grid-disabled-row a,
.rr-grid-disabled-row a:hover,
.rr-grid-disabled-row .rr-grid-shortName-cell,
.rr-grid-cancelled-row,
.rr-grid-cancelled-row a,
.rr-grid-cancelled-row a:hover,
.rr-grid-cancelled-row .rr-grid-shortName-cell {
    color: #abacb0; //fade(#575962, 40%);
}

.rr-grid-disabled-row a:hover .rr-delay-icon,
.rr-grid-disabled-row a:hover .rr-shortage-icon,
.rr-grid-cancelled-row a:hover .rr-delay-icon,
.rr-grid-cancelled-row a:hover .rr-shortage-icon {
    color: fade(#abacb0, 70%);
}

.rr-grid-disabled-row .anticon {
    color: fade(#abacb0, 70%) !important;
}

.faded-icon {
    color: fade(#abacb0, 70%) !important;
}

.rr-grid-disabled-row .ant-avatar-image,
.rr-grid-cancelled-row .ant-avatar-image,
.rr-grid-archive-row .ant-avatar-image,
.rr-grid-disabled-row .ant-avatar,
.rr-grid-cancelled-row .ant-avatar,
.rr-grid-archive-row .ant-avatar {
    opacity: 0.2;
    filter: grayscale(100%);
}

.rt-table .rr-problem-value {
    color: #f4516c;
}

.rt-table .rr-zero-value {
    color: #cccccc;
}

.rt-table .rr-grid-archive-row .rr-problem-value {
    color: #abacb0 !important;
}

//.rr-grid-disabled-row .rr-kit-avatar,
//.rr-grid-cancelled-row .rr-kit-avatar,
//.rr-grid-archive-row .rr-kit-avatar {
//    opacity: 0.2;
//    filter: grayscale(100%);
//}

//.rr-grid-disabled-row .rr-kit-avatar .anticon,
//.rr-grid-cancelled-row .rr-kit-avatar .anticon,
//.rr-grid-archive-row .rr-kit-avatar .anticon {
//    color: #fff !important;
//}

//
.ReactTable .rt-tbody {
    overflow: hidden;
}

.rt-table-pinned-columns {
    .rt-tbody {
        overflow: unset;
    }

    .rt-tr,
    .rt-thead {
        border-left: none !important;
        border-right: none !important;
    }
}

.ReactTable .rt-tbody .rr-grid-warning-row.rt-tr.-odd {
    background-color: #fff4de; //#ffeabe;

    & .rt-td-sticky {
        background-color: #fff4de; //#ffeabe;
    }
}

.ReactTable .rt-tbody .rr-grid-warning-row.rt-tr.-even {
    background-color: #ffeabe;

    & .rt-td-sticky {
        background-color: #ffeabe;
    }
}

.ReactTable .rt-tbody .rr-grid-warning-row .rr-status,
.ReactTable .rt-tbody .rr-grid-error-row .rr-status {
    //border: 2px solid #ffffff;
}

//
.ReactTable .rt-tbody .rr-grid-error-row.rt-tr.-odd {
    background-color: #fff3f4; //#fde4e6;

    & .rt-td-sticky {
        background-color: #fff3f4;
    }
}

.ReactTable .rt-tbody .rr-grid-error-row.rt-tr.-even {
    background-color: #fde4e6;

    & .rt-td-sticky {
        background-color: #fde4e6;
    }
}

.ReactTable .rt-tbody .rr-grid-error-row.rt-tr .rt-td > span,
.ReactTable .rt-tbody .rr-grid-warning-row.rt-tr .rt-td > span {
    //color: #ffffff !important; // TODO !important только для случая когда статус не задан
}

.ReactTable .rt-tbody .rr-grid-error-row .anticon,
.ReactTable .rt-tbody .rr-grid-warning-row .anticon {
    //color: #ffffff;
}

.ReactTable .rt-tbody .rr-grid-error-row a,
.ReactTable .rt-tbody .rr-grid-warning-row a {
    //color: #ffffff !important; // TODO !important только для случая когда элемент новый
}

.ReactTable .rt-tbody .rr-grid-error-row .rr-grid-shortName-cell,
.ReactTable .rt-tbody .rr-grid-warning-row .rr-grid-shortName-cell {
    //color: #ffffff !important; // TODO !important только для случая когда элемент новый
}

//.ReactTable .rt-tbody .rt-td .rr-grid-shortName-cell .new-value {
//  color: #1cb3a6;
//}

.ReactTable .rt-tbody .rr-grid-error-row .rr-dot,
.ReactTable .rt-tbody .rr-grid-warning-row .rr-dot {
    //background-color: #ffffff !important;
}

.rr-grid .ReactTable .rt-td a:hover {
    text-decoration: underline;
}

.rr-grid-status-cell {
    display: inline-block;
    width: 88px;
    font-size: 11px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    border-radius: 18px;
    padding: 4px;
}

/////////////////////////
.rr-grid-loading {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 4;
}

.rr-grid-loading .ant-spin {
    position: absolute;
    top: 50%;
    left: 50%;
}

/////////////////////////
/*.rt-th .rr-grid-sort-icon {
  display: none;
  position: absolute;
  //right: 0px;
  top: 50%;
  margin-top: -8px;
  font-size: 15px;
  //right: 2px;
  right: -3px;
}*/

.rt-th .rr-grid-sort-icon {
    opacity: 0;
    //display: none;
    //position: absolute;
    //right: 0px;
    //top: 50%;
    //margin-top: -8px;
    font-size: 15px;
    margin-left: 2px;
    //right: 2px;
    //right: -3px;
}

//.rt-th:not(:last-child) .rr-grid-sort-icon {
//right: 5px;//-8px;
//}

.-sort-asc .rr-grid-sort-icon,
.-sort-desc .rr-grid-sort-icon {
    //display: block;
    opacity: 1;
}

.-sort-desc .rr-grid-sort-icon > svg {
    transform: rotate(180deg);
}

.-sort-asc .rr-grid-sort-icon {
    margin-top: 6px;
}

.-sort-desc .rr-grid-sort-icon {
    margin-top: 3px;
}

// Выпадающее меню в гриде с действиями ////////////////////////////

.rr-grid-actions-popover,
.grid-additional-actions-popover,
.grid-additional-actions-popover-container {
    .ant-popover-inner-content {
        padding: 0;
    }
}

.rr-grid-actions-popover {
    width: 285px;
}

.rr-grid-actions-popover-hint {
    color: #ccc;
    font-size: 12px;
}

.grid-additional-actions-popover,
.grid-additional-actions-popover-container {
    .ant-popover-content > .ant-popover-arrow {
        border-top-color: #525a95;
        border-left-color: #525a95;
        background: #525a95;
    }
}

.grid-additional-actions-popover-container {
    width: min-content;
}

.grid-additional-actions-button {
    min-width: 285px;
    padding-right: 48px !important;
}

.rr-grid-actions-popover-header {
    min-height: 54px;
    //line-height: 54px;
    border-radius: 3px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color: #525a95;
    color: #fff;
    padding: 0px 16px;
    font-weight: 600;
    user-select: none;
    display: flex;
    align-items: center;
}

.rr-grid-actions-popover-header1 {
    min-height: 7px;
    //line-height: 54px;
    border-radius: 3px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color: #525a95;
    color: #fff;
    padding: 0px 16px;
    font-weight: 600;
    user-select: none;
    display: flex;
    align-items: center;
}

.rr-grid-actions-popover-header1 > div {
    min-height: 54px;
    color: #fff;
    padding: 0px 16px;
    font-weight: 600;
    user-select: none;
    display: flex;
    align-items: center;
}

//.rr-grid-actions-popover-header .anticon {
//  //position: relative;
//  //top: 2px;
//}

.rr-grid-actions-popover-header a,
.rr-grid-actions-popover-header a:hover {
    color: #fff;
}

.rr-grid-actions-popover-header .ant-input-number {
    height: 30px;
    width: 46px;
}

.rr-grid-actions-popover.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
.rr-grid-actions-popover.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
.rr-grid-actions-popover.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
    border-top-color: #525a95;
    border-left-color: #525a95;
}

.rr-grid-actions-popover-nested {
    width: 380px !important;
}

.rr-grid-actions-popover-content .ant-btn {
    border-width: 0;
    border-radius: 0;
    text-align: left;
    padding: 0 20px;
    height: 45px;
    color: #575962;
    animation-duration: 0s !important;
}

.rr-grid-actions-popover-custom-edit-value .rr-form-group {
    padding-top: 0;
    padding-bottom: 0;

    input[type='text'] {
        width: 80%;
    }

    .custom-checkbox .ant-radio-wrapper:last-child {
        display: none;
    }
}

.rr-grid-actions-popover-content .ant-btn.ant-btn-page-actions {
    width: 100%;
}

.rr-grid-actions-popover-content .ant-btn > .anticon {
    font-size: 21px;
    position: relative;
    top: 2px;
}

.rr-grid-actions-popover-content .ant-btn:hover {
    background-color: #f4f5f8;
    color: #525a95;
}

.rr-grid-actions-popover-content .ant-btn > .anticon + span,
.rr-grid-actions-popover-content .ant-btn > span + .anticon {
    margin-left: 22px;
}
.rr-grid-actions-popover-content .ant-btn > .rr-dot + span {
    margin-left: 22px;
}

.rr-grid-actions-popover-content .ant-btn[disabled],
.rr-grid-actions-popover-content .ant-btn[disabled]:hover {
    color: #c4c5d5;
    background: transparent;
}

.rr-grid-actions-popover-content .ant-btn[disabled] > .anticon {
    opacity: 0.25;
}

.rr-grid-actions-popover-content .ant-btn[ant-click-animating-without-extra-node]:after {
    animation: none;
}

.rr-grid-categories-header {
    display: flex;
    justify-content: center;
    background-color: #f4f5f8;
    font-weight: 600;
    max-height: 56px;
    line-height: 56px;
    height: 56px;

    span {
        display: flex;

        a {
            margin-left: 4px;

            i {
                &:hover {
                    opacity: 0.7;
                }
            }
        }

        .collapse-icon-container {
            display: flex;
            justify-content: start;
            align-content: center;
            width: 28px;
            cursor: pointer;

            .collapse-icon {
                align-self: center;
                margin-top: 1px;
                margin-left: 6px;

                svg {
                    color: #575962;
                    width: 1.3em;
                    height: 1.3em;
                }

                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }
}

.rr-grid-table-separator {
    height: 7px;
    background-color: #f8f9fa;
}

.rr-grid-notfound-block {
    text-align: center;
    margin-top: 56px;
    margin-bottom: 20px;
}

.rr-grid-notfound-block .ant-avatar {
    background-color: #e2e5ec;
}

.rr-grid-notfound-block-title {
    margin-top: 16px;
    font-size: 18px;
    color: #575962;
    font-weight: 600;
}

.rr-grid-notfound-block-message {
    margin-top: 5px;
}

.rr-grid-notfound-block-message2 {
    margin-top: 5px;
    color: #999999;
}

.anticon.rr-grid-table-actions-icon {
    font-size: 21px;
    color: #575962 !important;
}

.anticon.rr-grid-table-actions-icon:hover {
    color: fade(#575962, 75%) !important;
}

.anticon.rr-grid-table-actions-icon-disabled,
.anticon.rr-grid-table-actions-icon-disabled:hover {
    cursor: default;
    color: fade(#575962, 30%) !important;
}

.anticon.rr-grid-table-actions-icon.rr-grid-table-actions-icon-icon-edit {
    color: #525a95;
    font-size: 22px;
    margin-top: -2px;
}

.anticon.rr-grid-table-actions-icon.rr-grid-table-actions-icon-icon-edit:hover {
    color: fade(#525a95, 75%);
}

.anticon.rr-grid-table-actions-icon2 {
    font-size: 20px;
    color: #34bfa3;
    margin-right: 8px;
}

.anticon.rr-grid-table-actions-icon2:hover {
    color: fade(#34bfa3, 75%);
}

.anticon.rr-grid-table-actions-icon3 {
    font-size: 20px;
    color: #f4516c;
    margin-right: 8px;
}

.anticon.rr-grid-table-actions-icon3:hover {
    color: fade(#f4516c, 75%);
}

//// Иконка удаления в гриде
.anticon.rr-grid-table-remove-icon {
    font-size: 21px;
    color: #f4516c;
    cursor: pointer;
}

.anticon.rr-grid-table-remove-icon:hover {
    color: fade(#f4516c, 75%);
}
// Иконка редактирования в гриде
.anticon.rr-grid-table-edit-icon {
    font-size: 21px;
    color: #525891;
}

.anticon.rr-grid-table-edit-icon:hover {
    color: fade(#525891, 75%);
}

.anticon.rr-grid-table-otherActions-icon {
    color: #525891;
    cursor: pointer;
    font-size: 28px;
}

.anticon.rr-grid-table-otherActions-icon:hover {
    color: fade(#525891, 75%);
}

// Иконка загрузки файла
.anticon.rr-grid-table-upload-icon {
    font-size: 21px;
    //padding-top: 3px;
    vertical-align: middle;
    color: #34bfa3;
    cursor: pointer;
}

.anticon.rr-grid-table-upload-icon:hover {
    color: fade(#34bfa3, 75%);
}

.ant-upload-disabled .anticon.rr-grid-table-upload-icon {
    //font-size: 22px;
    //padding-top: 3px;
    //vertical-align: middle;
    color: #c4c5d5;
    cursor: default;
}

.ant-upload-disabled .anticon.rr-grid-table-upload-icon:hover {
    color: #c4c5d5;
}

// Иконка редактирования в гриде
.anticon.rr-grid-table-plus-icon {
    font-size: 21px;
    //padding-top: 3px;
    vertical-align: middle;
    color: #34bfa3;
}

.anticon.rr-grid-table-plus-icon:hover {
    color: fade(#34bfa3, 75%);
}

// Иконка редактирования в гриде
.anticon.rr-grid-table-double-right-icon {
    font-size: 22px;
    //padding-top: 3px;
    vertical-align: middle;
    color: #34bfa3;
}

.anticon.rr-grid-table-double-right-icon:hover {
    color: fade(#34bfa3, 75%);
}

// Иконка редактирования в гриде
.anticon.rr-grid-table-calendar-icon {
    font-size: 22px;
    //padding-top: 3px;
    vertical-align: middle;
    color: #525891; //#575962;
}

.anticon.rr-grid-table-calendar-icon:hover {
    color: fade(#525891, 75%);
}

/*.rr-grid-disabled-row .anticon.rr-grid-table-calendar-icon,*/
//.rr-grid-disabled-row .anticon.rr-grid-table-double-right-icon, .rr-grid-disabled-row .anticon.rr-grid-table-plus-icon {
//color: fade(#575962, 40%);
//cursor: default;
//}

.anticon.rr-grid-table-plus-icon-disabled,
.anticon.rr-grid-table-double-right-icon-disabled,
.anticon.rr-grid-table-plus-icon-disabled:hover,
.anticon.rr-grid-table-double-right-icon-disabled:hover {
    color: fade(#575962, 40%);
    cursor: default;
}

.rr-grid-pricingScheme-icon {
    font-size: 21px;
    color: #3d64d4;
    margin-right: 4px;
    margin-top: -3px;
    vertical-align: middle;
}

.rr-grid-disabled-row .rr-grid-pricingScheme-icon,
.rr-grid-cancelled-row .rr-grid-pricingScheme-icon {
    color: fade(#575962, 40%);
}

// Иконка редактирования в гриде
.anticon.rr-grid-table-move-from-to-kit-icon {
    font-size: 21px;
    //padding-top: 3px;
    vertical-align: middle;
    color: #36a3f7;
}

.anticon.rr-grid-table-move-from-to-kit-icon:hover {
    color: fade(#36a3f7, 75%);
}

.ReactTable .rt-td.rr-grid-table-cell-fix {
    //padding: 0;
    //height: 100%;
}

.ReactTable .rt-td.rr-grid-table-cell-fix > div {
    padding: 0;
}

.ReactTable .rt-td.rr-grid-table-cell-fix > div > div {
    //padding: 0;
    height: 100%;
    max-height: unset;
}

.ReactTable .rt-td.rr-grid-table-cell-fix .rr-grid-table-cell-fix-container {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
}

.rr-grid-excel-icon {
    color: #21bfa4;
}

.rr-grid-excel-icon:hover {
    opacity: 0.8;
}

.rr-grid-export-button {
    display: inline-flex;

    align-items: center;
    justify-content: center;

    cursor: pointer;
    user-select: none;
}

.rr-grid-export-button:hover {
    opacity: 0.8;
}

.rr-grid-export-button > i {
    color: #21bfa4;
}

.rr-grid-export-button > span {
    margin-left: 6px;
    white-space: nowrap;
}

.rr-grid-settings-icon {
    color: #575962;
}

.rr-grid-settings-icon:hover {
    opacity: 0.8;
}

.rr-grid-table-modified-cell {
    //background-color: #ffe3a7;
    font-weight: 600;
}

.rr-grid-table-warning-cell {
    background-color: #ff8d3a;
    //background-color: #f4516c;
    color: #ffffff !important;
    font-weight: 600 !important;
}

.rr-grid-table-error-cell {
    background-color: #f4516c;
    font-weight: 600 !important;
    color: #ffffff !important;
}

.rr-grid-table-warning-cell .new-value,
.rr-grid-table-error-cell .new-value {
    font-weight: 600 !important;
    color: #ffffff;
}

//.ReactTable .rt-tbody .rt-td .rr-grid-table-warning-cell .new-value {
//  color: #fff;
//}

.ReactTable .rt-tbody .rt-td .rr-grid-table-warning-cell .new-value,
.ReactTable .rt-tbody .rt-td .rr-grid-table-error-cell .new-value {
    color: #fff;
}

.ReactTable .rt-tbody .rr-grid-error-row .rr-grid-table-modified-cell,
.ReactTable .rt-tbody .rr-grid-warning-row .rr-grid-table-modified-cell {
    //background-color: fade(#ffe3a7, 15%);
}

//
.ReactTable .rt-tbody .rt-td .new-value {
    color: #1cb3a6;
}

//
.ReactTable .rt-tbody .rt-td .main-value {
    color: #343f9a;
}

.ReactTable .rt-tbody .rr-grid-disabled-row .rt-td .main-value,
.ReactTable .rt-tbody .rr-grid-disabled-row .rt-td .new-value,
.ReactTable .rt-tbody .rr-grid-error-row .rt-td .main-value,
.ReactTable .rt-tbody .rr-grid-error-row .rt-td .new-value,
.ReactTable .rt-tbody .rr-grid-warning-row .rt-td .main-value,
.ReactTable .rt-tbody .rr-grid-warning-row .rt-td .new-value {
    //color: unset;
}

.ReactTable .rt-tbody .rr-grid-disabled-row .rt-td .main-value,
.ReactTable .rt-tbody .rr-grid-disabled-row .rt-td .new-value,
.ReactTable .rt-tbody .rr-grid-disabled-row .rt-td .rr-grid-id,
.ReactTable .rt-tbody .rr-grid-cancelled-row .rt-td .main-value,
.ReactTable .rt-tbody .rr-grid-cancelled-row .rt-td .new-value,
.ReactTable .rt-tbody .rr-grid-cancelled-row .rt-td .rr-grid-id {
    color: unset;
}

.rr-grid-categories-collapse-icon {
    top: 17px;
    left: 16px;
    position: absolute;
}

.rr-grid-categories-button {
    display: inline-flex;
    align-items: center;
    margin-right: 30px;
    cursor: pointer;
    user-select: none;

    .rr-grid-categories-icon {
        margin-left: 8px;
    }

    .rr-grid-categories-count {
        font-weight: 600;
        color: #383f77;
        padding-left: 4px;
    }

    &:hover {
        opacity: 0.85;
    }
}

.rr-grid-categories-collapse-icon,
.rr-grid-categories-icon {
    color: #383f77;
    font-size: 21px;
}

.rr-grid-categories-collapse-icon:hover
 {
    opacity: 0.85;
}

.rr-calendar-popup-header {
    color: #383f77;
    font-weight: 700;
    margin-bottom: 10px;
}

.rr-grid-columns-control-popover-overlay {
    .ant-popover-inner-content {
        padding: 0 !important;
    }
}

.rr-grid-columns-control-popover-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-height: 600px;
    overflow-y: auto;
    padding: 18px;
}

.rr-grid-columns-control-popover-content .ant-checkbox-wrapper:not(:last-child) {
    margin-bottom: 8px;
}

/////// export excel

.rr-grid-export-excel-content {
    margin: 20px;
    margin-left: 24px;
}

.rr-grid-export-excel-group {
    width: 100%;
}

.rt-tr-group div {
    width: 100%;
}

.rr-grid-export-excel-button {
    margin-top: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    user-select: none;
}

.rr-grid-export-excel-icon {
    font-size: 21px;
    margin-right: 10px;
    margin-top: -2px;
}

.rr-grid-actions-export-check {
    padding-bottom: 8px;
}

.rr-grid-actions-popover-export-excel {
    width: 220px;
}
////// end export excel

.rr-grid-table-actions-group {
    display: flex;
    width: 100%;
    line-height: 1;
}

.rr-grid-table-actions-group > * {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ReactTable .rt-tbody .rt-td.money {
    text-align: right;
}

.ReactTable .rt-tbody .rt-td.money > div {
    justify-content: right;
    padding-right: 20px;
}

.ReactTable .rt-tbody .rr-grid-archive-row .rt-td.shiftCount {
    color: #cccccc;
}

.rr-grid-compact-view .ReactTable div.rt-tr {
    height: 45px;
}

.rr-grid-compact-view .ReactTable .rt-tbody .rt-td > div {
    height: 45px;
}

.checkboxes-popover-text {
    line-height: 24px;
    color: #bcbcbc;
    display: block;
    width: 100%;
    max-width: 100%;

    a {
        color: #343e99;
        text-decoration: underline;

        &:hover {
            opacity: 0.8;
        }
    }
}

.rr-grid-filters,
.rr-grid-smart_filters {
    .ant-select {
        max-width: 350px !important;
    }
}

.rr-grid-footer-left {
    display: flex;
    align-items: center;
    gap: 16px;
}

.rr-grid-footer-left .ant-pagination {
    display: flex;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';